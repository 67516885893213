import "./App.css";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { RadioGroup } from "@headlessui/react";

function App() {
  const [value, setValue] = useState("");
  const [color, setColor] = useState("#e2e8f0");
  let [plan, setPlan] = useState("#000000");
  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };
  const onChangeText = (e) => {
    setValue(e.target.value);
    if (e.target.value !== "") {
      setColor(plan);
    } else {
      setColor("#e2e8f0");
    }
  };
  const onChangeColor = (colorValue) => {
    setPlan(colorValue);
    if (value !== "") {
      setColor(colorValue);
    }
  };
  return (
    <div className="flex md:justify-center h-screen md:items-center">
      <div className="flex flex-col justify-start">
        <div className="bg-white w-full md:w-[400px] h-full md:h-fit flex flex-col items-center  md:rounded-t-xl shadow p-8">
          <img src="illustration.png" className="h-16 mt-3 hidden md:block" />
          <div className="text-center my-10 md:my-5">
            <span className="font-medium text-lg md:text-sm">
              Crée ton propre QR-Code
            </span>
            <p className="text-sm text-slate-400">
              Sur cette page tu peux générer autant de QR-Code que tu souhaites.
              Insert ton URL dans le champ ci-dessous et choisit ta couleur.
            </p>
          </div>
          <div
            className={
              "rounded-xl w-fit h-fit p-3 transition-colors duration-200 " +
              (color === "#FFFFFF" ? "bg-slate-200" : "bg-white")
            }
          >
            <QRCode
              id="QRCode"
              style={{ height: "120px", maxWidth: "100%", width: "100%" }}
              viewBox={`0 0 512 512`}
              fgColor={color}
              bgColor={"transparent"}
              value={value}
              size={512}
            />
          </div>
          <input
            className="border border-slate-200 w-full md:w-72 py-3 px-2 my-10 md:my-6 rounded-lg placeholder:text-slate-400  text-sm focus:outline-purple-500"
            value={value}
            placeholder="https://"
            type="url"
            onChange={(e) => onChangeText(e)}
          ></input>
          <div className="mb-10 md:mb-4 w-4/5 md:w-2/3">
            <RadioGroup
              className={"flex flex-row flex-wrap justify-center"}
              value={plan}
              onChange={(value) => onChangeColor(value)}
            >
              <RadioGroup.Option value="#000000">
                {({ checked, active }) => (
                  <div
                    className={
                      "bg-black rounded-full w-10 h-10 md:w-6 md:h-6 mr-2 cursor-pointer mb-2 ring-offset-2 ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#FFFFFF">
                {({ checked }) => (
                  <div
                    className={
                      "bg-white border border-black rounded-full w-10 h-10 md:w-6 md:h-6 mr-2 cursor-pointer mb-2 ring-offset-2 ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2" : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#97233f">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#97233f] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#94a3b8">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#94a3b8] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#dc2626">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#dc2626] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#f97316">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#f97316] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#fde047">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#fde047] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#a3e635">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#a3e635] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#14b8a6">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#14b8a6] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#3b82f6">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#3b82f6] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#a855f7">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#a855f7] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option value="#f43f5e">
                {({ checked }) => (
                  <div
                    className={
                      "bg-[#f43f5e] rounded-full w-10 h-10 md:w-6 md:h-6 mr-2  mb-2 ring-offset-2 cursor-pointer ring-purple-600 hover:ring-2 transition-all duration-200 " +
                      (checked ? "ring-2 " : "")
                    }
                  ></div>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          </div>
          <input
            type="button"
            value="Télécharger"
            className="w-full md:w-44 font-medium bg-purple-600 px-4 py-5 md:py-3 rounded-lg text-white text-sm cursor-pointer hover:bg-purple-700"
            onClick={() => onImageCownload()}
          />
        </div>
        <div className="bg-slate-100 p-6 flex flex-col text-center md:text-left justify-evenly md:rounded-b-lg md:w-[400px] shadow text-sm text-slate-400 h-20">
          <p>fait avec 💜 par le Service Informatique de Grangeneuve</p>
          <a
            href="https://www.flaticon.com/free-icons/qr-scan"
            title="qr scan icons"
            className="text-xs"
          >
            Qr scan icons created by Freepik - Flaticon
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
